import Button from 'react-bootstrap/Button';
import Form from "react-bootstrap/Form";
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Alert from 'react-bootstrap/Alert';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from "react";
import {Fragment} from "react";
import AppContext from "../contexts/AppContext";
import { useState, useEffect, useContext, useRef} from "react";
import AuthService from "../services/auth-service";
import { useHistory } from "react-router-dom";
import {Key, Lock, PersonFill} from 'react-bootstrap-icons';

const Login = () => {
    const mailInput = React.createRef();
    const passwordInput = React.createRef();
    const contextApplication = useContext(AppContext);
    const [message, setMessage] = useState("");
    const history = useHistory();

    const handleLogin = (e) => {
        e.preventDefault();

        const username = mailInput.current.value;
        const password = passwordInput.current.value;

        AuthService.login(username, password).then(
            (response) => {
                let user = response.user
                let token = response.token
                contextApplication.generateToken(user, token);

                history.push("/applications");

            },
            (error) => {

                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                setMessage(resMessage);


            }
        );

    };

    return (

            <Container className="login-form">
                <Row>
                    <Col md={6} className="mx-auto">
                        <Card className="Mycard align-self-center">
                            <Card.Header className="text-center">
                                <Lock size={24} /> Logging

                            </Card.Header>
                            <Card.Body>
                               <Form onSubmit={handleLogin}>
                                    <Form.Group>


                                        <Form.Label>email</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="basic-addon1">@</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl placeholder="email address" id="email" name="email" ref={mailInput}
                                                         >
                                            </FormControl>
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group>
                                        <Form.Label>Password</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="basic-addon1">
                                                    <Key />
                                                </InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <FormControl name="password"
                                                         type="password"
                                                         id="password"
                                                         ref={passwordInput}
                                                          >
                                            </FormControl>
                                        </InputGroup>
                                    </Form.Group>

                                   {message && (
                                       <div className="form-group">
                                           <div className="alert alert-danger" role="alert">
                                               {message}
                                           </div>
                                       </div>
                                   )}

                                    <Form.Group controlId="formBasicButton">
                                         <Button variant="secondary" id="login-button" type="submit" className="btn-block">Accedi</Button>
                                    </Form.Group>

                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>


    )
};

export default Login