import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import React from "react";

function ItemStats(props){
    return (
        <ListGroup.Item>
            <Row>
                <Col md={7} className="text-right">
                    <h6>{props.title_item}</h6>
                </Col>
                <Col md={5} className="text-right">
                    <h6>{props.value_item}</h6>
                </Col>
            </Row>
        </ListGroup.Item>
    )
}

export default ItemStats;