import AppContext from './../contexts/AppContext';
import React, {Component} from "react";
import AuthService from './../services/auth-service'
class AppProvider extends Component {
    state = {
        api_token: null,
        user: null,
        applications: [],

        getApplications: () => {
            AuthService.getApplications().then(
                (response) => {
                    console.log(response.data)
                    this.setState({applications: response.data})
                },
                (error) => {
                    const resMessage =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        getErrorMessage: (error) => {
            const resMessage =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            return resMessage;
        },

        updateApplications: (applications) => {
            this.setState({applications: applications})
        },


        updateApplication: (index, application) => {
            let apps = [...this.state.applications]
            apps[index] = application
            this.setState({applications: apps})
        },


        generateToken: (user, token) => {
            localStorage.setItem('api_token', token)
            localStorage.setItem('user', user.name)
            this.setState({api_token: token})
            this.setState({user: user.name})
        },

        setTokenStorageContext:() => {
            if(localStorage.getItem('api_token') !== null){
                const token = localStorage.getItem('api_token')
                const user = localStorage.getItem('user')
                this.setState({api_token: token})
                this.setState({user: user})
            }
            else{
                this.setState({api_token: null})
                this.setState({user: null})
            }
        },


        removeToken:() => {
            localStorage.clear();
            this.setState({api_token: null})
            this.setState({user: null})

        }
    }

    render() {
        return (
            <AppContext.Provider
                value={ this.state}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default AppProvider;