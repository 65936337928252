import './App.css';
import React, {Component} from "react";
import Topbar from './components/Topbar';
import Login from './components/Login';
import Applications from './components/applications/Index';

import AppContext from "./contexts/AppContext";
import {useEffect, useContext} from "react";
import {Switch, Route, NavLink, Link, withRouter, useHistory} from "react-router-dom";


const App = (props) => {
        const contextApplication = useContext(AppContext);
        const history = useHistory();
        useEffect(() => {
            const token = localStorage.getItem('api_token')

             contextApplication.setTokenStorageContext();
            if(token){
                history.push("/applications");
            }
        }, []);

        return (
            <div className="App">
                <header className="App-header">
                    <Topbar />
                    <AppContext.Consumer>
                        {context => (context.api_token !== null) ? (
                            <Switch>
                                <Route exact path="/applications" component={Applications}/>

                            </Switch>
                            ) : (
                            <Login />
                        )}
                    </AppContext.Consumer>
                </header>
            </div>

        );
};
export default withRouter(App)

