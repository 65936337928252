import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {BarChart} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import React from "react";
import {Fragment} from "react";
import ListGroup from "react-bootstrap/ListGroup";
import ItemStats from "./components/stats/ItemStats";
import ItemGroupStats from "./components/stats/ItemGroupStats";
function Stats(props){
    return (
        <Fragment>
            <Card.Header className="text-left">
                <Row>
                    <Col md={6}>
                        <h4>
                            <BarChart /> Statistiche
                        </h4>
                    </Col>
                    <Col md={6} className="text-right">
                        <Button size="sm" variant="primary" className="mr-1" onClick={() => props.setShowStat(false)}>
                            <BarChart /> Chiudi Statistiche
                        </Button>
                    </Col>
                </Row>
            </Card.Header>

            <Card.Body>
                <Row>
                    {props.stat.logEventStat != null && (
                        <Col md={6} className="text-left">
                            <h5>Events</h5>
                            <ListGroup>
                                <ItemStats title_item="Nome Collection:" value_item={props.stat.logEventStat.ns} />
                                <ItemStats title_item="Dimensione Totale (KB):" value_item={props.stat.logEventStat.size} />
                                <ItemStats title_item="Dimensione Media Documento (KB):" value_item={props.stat.logEventStat.avgObjSize} />
                                <ItemStats title_item="Dimensione storage (KB):" value_item={props.stat.logEventStat.storageSize} />
                                <ItemStats title_item="Numero documenti:" value_item={props.stat.logEventStat.count} />

                                <ListGroup.Item>
                                    {props.stat.logEventStat.count != 0 && (
                                        <ItemGroupStats title="Tenancy" grouped={props.stat.eventTenancies} />
                                    )}
                                </ListGroup.Item>

                            </ListGroup>
                        </Col>
                    )}

                    {props.stat.logDataStat != null && (

                        <Col md={6} className="text-left">
                            <h5>Data</h5>
                            <ListGroup>
                                <ItemStats title_item="Nome Collection:" value_item={props.stat.logDataStat.ns} />
                                <ItemStats title_item="Dimensione Totale (KB):" value_item={props.stat.logDataStat.size} />
                                <ItemStats title_item="Dimensione Media Documento (KB):" value_item={props.stat.logDataStat.avgObjSize} />
                                <ItemStats title_item="Dimensione storage (KB):" value_item={props.stat.logDataStat.storageSize} />
                                <ItemStats title_item="Numero documenti:" value_item={props.stat.logDataStat.count} />
                                <ListGroup.Item>
                                    {props.stat.logDataStat.count != 0 && (
                                        <Fragment>
                                            <ItemGroupStats title="Tenancy" grouped={props.stat.dataTenancies} />
                                            <ItemGroupStats title="Azione" grouped={props.stat.dataActions} />
                                            <ItemGroupStats title="Risorsa" grouped={props.stat.dataResources} />
                                        </Fragment>
                                    )}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    )}

                </Row>
            </Card.Body>
        </Fragment>
    )
}

export default Stats;