import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import React from "react";
import {Fragment} from "react";

function ItemGroupStats(props){
    return (
        <Fragment>
            <Row>
                <Col md={7} className="text-right">
                    <h6><b>{props.title}</b></h6>
                </Col>
            </Row>
            {props.grouped.map(tenancy => {
                return <Row key={tenancy._id}>
                    <Col md={8} className="text-right">
                        <h6>{tenancy._id}</h6>
                    </Col>
                    <Col md={4} className="text-right">
                        <h6>{tenancy.count}</h6>
                    </Col>
                </Row>
            })}
            <hr />
        </Fragment>
    )
}

export default ItemGroupStats;