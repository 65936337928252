
import React, {Fragment, useState, useEffect, useRef, useContext} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Display, Envelope, Flag, Gear, Key, Layers, SegmentedNav, CheckLg, XLg} from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import FormBootstrap from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import AuthService from "../../services/auth-service";
import { useAlert } from 'react-alert'
import AppContext from "../../contexts/AppContext";

function Form(props){
    const contextApplication = useContext(AppContext);
    const textMailUpdateApp = 3;
    const alert = useAlert()
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [sMail, setSmail] = useState(false);

    const closeForm = () => {
        props.setEditMode(false)
    }

    const onSubmit = (data) => {
        let appData = {...props.app}
        if(props.creating){
            appData.name = data.name_app
        }
        appData.description = data.description
        appData.email = data.email
        appData.company_name = data.company_name
        appData.expires_in = data.expires_in

        if(props.creating){
            AuthService.createApp(appData).then(
                (response) => {
                    contextApplication.getApplications()
                    alert.success('Modifica eseguita correttamente!')
                    closeForm()
                    contextApplication.getApplications();
                },
                (error) => {
                    alert.error(contextApplication.getErrorMessage(error));
                }
            )
        }
        else{
            AuthService.updateApp(appData).then(
                () => {
                    contextApplication.updateApplication(props.index, appData)
                    alert.success('Modifica eseguita correttamente!')
                    closeForm()
                    if(sMail)
                        props.sendMail(textMailUpdateApp)
                },
                (error) => {
                    //alert.error(contextApplication.getErrorMessage(error));
                }
            )
        }
    };

    return (
        <FormBootstrap onSubmit={handleSubmit(onSubmit)}>

            {props.creating && <Fragment>


                <FormBootstrap.Group as={Row} controlId="formAltro">

                    <FormBootstrap.Label column sm="2">
                        Nome Applicazione
                    </FormBootstrap.Label>
                    <Col sm="10">
                        <FormBootstrap.Control id={"nameApp"} type="text" {...register("name_app", { required: true })} defaultValue={props.app.name_app} />
                    </Col>
                    {errors.name_app && <Col md={{ span: 10, offset: 2 }}>
                        <Fragment>
                            <Alert variant="danger smallest">
                                Campo obbligatorio!
                            </Alert>
                        </Fragment>

                    </Col>
                    }

                </FormBootstrap.Group>
            </Fragment>
            }




            <FormBootstrap.Group as={Row} controlId="formDescription">

                <FormBootstrap.Label column sm="2">
                    Descrizione
                </FormBootstrap.Label>
                <Col sm="10">
                    <FormBootstrap.Control id={"description"+ props.index} type="text" {...register("description", { required: true })} defaultValue={props.app.description} />
                </Col>
                {errors.description && <Col md={{ span: 10, offset: 2 }}>
                        <Fragment>
                            <Alert variant="danger smallest">
                                Campo obbligatorio!
                            </Alert>
                        </Fragment>

                    </Col>
                }

            </FormBootstrap.Group>



            <FormBootstrap.Group as={Row} controlId="formCompanyName">

                <FormBootstrap.Label column sm="2">
                    Azienda
                </FormBootstrap.Label>
                <Col sm="10">
                    <FormBootstrap.Control id={"companyName"+ props.index} type="text" {...register("company_name", { required: true })} defaultValue={props.app.company_name} />
                </Col>
                {errors.company_name && <Col md={{ span: 10, offset: 2 }}>
                    <Alert variant="danger smallest">
                        Campo obbligatorio!
                    </Alert>
                </Col>

                }
            </FormBootstrap.Group>

            <FormBootstrap.Group as={Row} controlId="formEmail">
                <FormBootstrap.Label column sm="2">
                    Email Referente
                </FormBootstrap.Label>
                <Col sm="10">
                    <FormBootstrap.Control id={"email"+ props.index} type="text" {...register("email", {
                        pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                        required: true
                    })} defaultValue={props.app.email} />
                </Col>
                {errors.email && <Col md={{ span: 10, offset: 2 }}>
                    <Alert variant="danger smallest">
                        Campo obbligatorio!
                    </Alert>
                </Col>

                }
            </FormBootstrap.Group>

            <FormBootstrap.Group as={Row} controlId="formExpiresIn">
                <FormBootstrap.Label column sm="2">
                    Conservazione Logs (mesi)
                </FormBootstrap.Label>
                <Col sm="10">
                    <FormBootstrap.Control type="number" {...register("expires_in", { required: true, min: 1, max: 48 })} defaultValue={props.app.expires_in} />
                </Col>
                {errors.expires_in && <Col md={{ span: 10, offset: 2 }}>
                    <Alert variant="danger smallest">
                        Campo obbligatorio! Il campo deve essere compreso tra 1 e 48
                    </Alert>
                </Col>
                }
            </FormBootstrap.Group>


            <Row>
                <Col md={12} className="text-right">
                    <Button onClick={closeForm} size="sm" variant="outline-primary" className="mr-1"><XLg /> Annulla</Button>
                    {props.creating ? (
                        <Fragment>

                            <Button id={"storeApp"} type="submit" size="sm" variant="outline-success" className="mr-1"><CheckLg/>Salva Nuova App</Button>

                        </Fragment>
                    ) : (
                        <Fragment>


                            <Button type="submit" size="sm" variant="outline-success" className="mr-1"><CheckLg/>Salva Modifiche</Button>
                            <Button onClick={()=>setSmail(true)} type="submit" size="sm" value="true" variant="outline-success" className="mr-1"><CheckLg/> Salva Modifica e invia mail</Button>
                        </Fragment>
                    )}
                </Col>
            </Row>

        </FormBootstrap>
    )
}

export default Form;