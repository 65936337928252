import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
const { forwardRef, useRef, useImperativeHandle } = React;
const ModalToken = forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const updateToken = () => {
        props.updateToken()
        handleClose()
    };

    useImperativeHandle(ref, () => ({
        openModal() {
            handleShow();
        }
    }));

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Aggiorna token per App {props.appName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sicuro di voler aggiornare il token dell'applicazione {props.appName}? <br /> Verrà inviata una mail al referente del prodotto</Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={handleClose}>
                    Annulla
                </Button>
                <Button size="sm" variant="primary" onClick={updateToken}>
                    Conferma
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default ModalToken;