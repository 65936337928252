import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Envelope, Gear, Key, Layers, SegmentedNav, CheckLg, Alarm, XLg} from "react-bootstrap-icons";
import React, {Fragment, useContext, useRef, useState} from "react";

function Show(props){
    return (
        <Fragment>
            <Row>
                <Col md={3}>
                    <p className="description">{props.app.description}</p>
                </Col>
                <Col md={3}>
                    <label> <Envelope size={24} /> Email</label>
                    <h6>{props.app.email}</h6>
                </Col>

                <Col md={3}>
                    <label><Key size={24} /> Token</label>
                    <p className="description">{props.app.token}</p>
                </Col>


                <Col md={3} className="text-right ">
                    <label><Gear size={24} /> Stato</label>
                    {props.app.active ? (
                        <h6><CheckLg color="green"/> Attivo</h6>
                    ) : (
                        <h6><XLg color="red" /> Sospeso</h6>
                    )}
                </Col>
            </Row>
        </Fragment>
    )
}

export default Show;