import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
//import NavLink from 'react-bootstrap/NavLink';
import {Switch, Route, NavLink, Link, withRouter} from "react-router-dom";

import Button from 'react-bootstrap/Button';
import AppContext from "../contexts/AppContext";
import Login from "./Login";
import React, {useContext} from "react";
import AuthService from "../services/auth-service";
import {Key, Power} from 'react-bootstrap-icons';

function Topbar(){

    const contextApplication = useContext(AppContext);

    const Logout = () => {
        //contextApplication.removeToken();
        AuthService.logout().then(
            (response) => {
                console.log(response)
                contextApplication.removeToken();
                window.location.href = '/';
            },
            (error) => {
                const resMessage =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();
            }
        );
    }




    return (
        <div>
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" fixed="top">
                <Navbar.Brand>
                    Logger
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">

                    </Nav>

                    <AppContext.Consumer>
                    {context => (context.api_token !== null) ? (


                        <AppContext.Consumer>

                            {(context) =>
                                <React.Fragment >
                                    <NavLink to={"/applications"} className="nav-link mr-3">
                                        <small>{context.user}</small>
                                    </NavLink>

                                <Nav>

                                    <Button size="sm" variant="outline-primary" onClick={Logout}>
                                        <Power /> Logout
                                    </Button>
                                </Nav>
                                </React.Fragment>
                            }

                        </AppContext.Consumer>
                    ) : (
                        <p></p>

                    )}
                    </AppContext.Consumer>



                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default Topbar;