import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
const { forwardRef, useRef, useImperativeHandle } = React;
const ModalState = forwardRef((props, ref) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const changeState = () => {
        props.changeState()
        handleClose()
    };

    useImperativeHandle(ref, () => ({
        openModal(active) {
            console.log(active)
            handleShow();
        }
    }));

    let statusApp
    console.log(props.active)
    if(props.active) statusApp="disattivare"
    else statusApp="attivare"

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Modifica stato per App {props.appName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Sicuro di voler {statusApp}  l'applicazione {props.appName}? <br /> Verrà inviata una mail al referente del prodotto</Modal.Body>
            <Modal.Footer>
                <Button size="sm" variant="secondary" onClick={handleClose}>
                    Annulla
                </Button>
                <Button size="sm" variant="primary" onClick={changeState}>
                    Conferma
                </Button>
            </Modal.Footer>
        </Modal>
    );
});

export default ModalState;