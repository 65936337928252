import {get, post, put, del} from '../api/api';

const login = (email, password) => {
    let payload = {}
    payload.email = email
    payload.password = password
    return post("/user/login", payload)
        .then((response) => {
            return response.data;
        });
};

const logout = () => {
    return post("/user/logout", localStorage.getItem('api_token')).then((response) => {
        return response.data;
    });
};

const getApplications = () => {
    return get("/app").then((response) => {
        return response;
    });
};

const updateApp = (payload) => {
    return put('/app', payload).then((response) => {
        return response;
    });
};

const createApp = (payload) => {
    return post('/app', payload).then((response) => {
        return response;
    });
};

const updateToken = (payload) => {
    return post('/app/update-token', payload).then((response) => {
        return response;
    });
};

const changeState = (payload) => {
    console.log(payload)
    return post('/app/change-state', payload).then((response) => {
        return response;
    });
};

const seeAppStats = (params) => {
    console.log(params)
    return get('/app/show/stats', params).then((response) => {
        return response;
    });
};

const sendMail = (payload) => {
    return post('/app/sendmail', payload).then((response) => {
        return response;
    })
}



let exp={
    login,
    logout,
    getApplications,
    updateApp,
    createApp,
    updateToken,
    changeState,
    seeAppStats,
    sendMail
}

export default exp
