import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Display, ArrowClockwise, Flag, Pencil, BarChart, Envelope } from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import Show from './Show'
import Form from './Form'
import React, { useContext } from "react";
import { useState, useRef } from "react";
import { Fragment } from "react";
import AuthService from "../../services/auth-service";
import { useAlert } from 'react-alert'
import ModalToken from "./components/commons/ModalToken";
import ModalState from "./components/commons/ModalState";
import AppContext from "../../contexts/AppContext";
import Stats from "./Stats";
function Item(props) {
    const textMailChangeState = 1;
    const textMailUpdateToken = 2;
    const contextApplication = useContext(AppContext);
    const alert = useAlert();
    const [editMode, setEditMode] = useState(false);
    const [showStat, setShowStat] = useState(false);
    const [stat, setStat] = useState(null);
    const modalTokenRef = useRef();
    const modalStateRef = useRef();

    const changeState = () => {
        let newState
        (props.app.active)
            ? newState = false
            : newState = true
        const payload = {};
        payload.name = props.app.name
        payload.state = newState

        AuthService.changeState(payload).then(() => {
                let app = {...props.app}
                app.active = newState
                contextApplication.updateApplication(props.index, app)
                alert.success('Modifica stato eseguita correttamente!')
                sendMail(textMailChangeState)
            },
            (error) => {
                alert.error(contextApplication.getErrorMessage(error));
            }
        );
    }

    const updateToken = () => {
        const payload = {};
        payload.name = props.app.name
        AuthService.updateToken(payload).then(
            (response) => {
                let app = {...props.app}
                app.token = response.data
                contextApplication.updateApplication(props.index, app)
                alert.success('Modifica stato eseguita correttamente!')
                sendMail(textMailUpdateToken)
            },
            (error) => {
                alert.error(contextApplication.getErrorMessage(error));
            }
        );
    }

    const seeAppStats = () => {
        const payload = {};
        payload.name = props.app.name
        AuthService.seeAppStats(payload).then(
            (response) => {
                setStat(response.data)
                console.log(response.data)
                setShowStat(true)
            },
            (error) => {
                alert.error(contextApplication.getErrorMessage(error));
            }
        );
    }

    const edit = () => {
        setEditMode(true);
    }

    const sendMail = (type) => {
        let payload = {...props.app}
        payload.retain = props.app.expires_in
        payload.type = type
        AuthService.sendMail(payload).then(
            () => {
                alert.success('Mail inviata correttamente!')
            },
            (error) => {
                alert.error(contextApplication.getErrorMessage(error));
            }
        );
    }

    return (
        <Fragment>
            <Card className="Mycard align-self-center" key={props.app.name}>

                <Card.Header className="text-left">
                    <Row>
                        <Col md={6}>
                            <h4>
                                <Display /> {props.app.name}
                            </h4>
                        </Col>
                        <Col md={6} className="text-right ">
                            <h4><Flag /><i> {props.app.company_name}</i></h4>
                        </Col>
                    </Row>
                </Card.Header>

                <Card.Body className="text-left">

                    {editMode ? (
                        <Form app={props.app} sendMail={sendMail} setEditMode={setEditMode} index={props.index} />
                    ) : (
                        <Show app={props.app} setEditMode={setEditMode} />
                    )}
                    <Row>
                        <Col md={12} className="text-right">
                            {editMode ? (
                                <Fragment>

                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Button id={"edit_"+props.index} onClick={edit} size="sm" variant="primary" className="mr-1"><Pencil /> Modifica</Button>
                                    <Button onClick={seeAppStats} size="sm" variant="primary" className="mr-1">
                                        <BarChart /> Statistiche
                                    </Button>
                                    <Button onClick={() => modalTokenRef.current.openModal()} size="sm" variant="warning" className="mr-1">
                                        <ArrowClockwise /> Rigenera Token
                                    </Button>
                                    <Button onClick={() => modalStateRef.current.openModal()} size="sm" variant="warning" className="mr-1">
                                        <ArrowClockwise /> Cambia Stato
                                    </Button>
                                    <Button onClick={() => sendMail(4)} size="sm" variant="success">
                                        <Envelope /> Invia mail
                                    </Button>
                                </Fragment>
                            )}
                        </Col>
                    </Row>
                </Card.Body>

                {showStat && (
                    <Stats stat={stat} setShowStat={setShowStat} />
                )}
            </Card>

            <ModalToken ref={modalTokenRef} updateToken={updateToken} />
            <ModalState ref={modalStateRef} changeState={changeState} active={props.app.active} />

        </Fragment>
    )
}

export default Item;