import React, {useState, useRef, useEffect, useContext} from "react";
import {withRouter} from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Form from './Form'
import Item from "./Item";
import {Display, Flag, Plus} from 'react-bootstrap-icons';
import {Fragment} from "react";
import AppContext from "../../contexts/AppContext";


function Index(){
    const contextApplication = useContext(AppContext);
    const [message, setMessage] = useState("");
    const [creatingMode, setCreatingMode] = useState(false);
    const creating = () => {
        setCreatingMode(true);
    }

    const setEditMode = () => {
        setCreatingMode(false);
    }

    const [newApp] = useState(
         {
            name_app: '',
             company_name: '',
            description: '',
            email: '',
            expires_in: 24,
        }
    );

    useEffect(() => {
        contextApplication.getApplications();
    }, []);

   return (
       <Container className="applications-list">
           {message && (
               <div className="alert alert-danger" role="alert">
                   {message}
               </div>
           )}
           {!creatingMode ? (
               <Row className="text-right mb-4">
                   <Col md={12}>
                       <Button size="sm" id={"createApp"} onClick={creating} variant="primary">
                           <Plus size={20} />Registra App
                       </Button>
                   </Col>
               </Row>
           ) : (
               <Fragment>
                   <Row className="text-right mb-4">
                       <Col md={12}>
                           <Card className="Mycard align-self-center">
                               <Card.Header className="text-left">
                                   <Row>
                                       <Col md={6}>
                                           <h4>
                                               <Display /> Registra Nuova App
                                           </h4>
                                       </Col>
                                       <Col md={6} className="text-right ">
                                           <h4><Flag /><i>
                                           </i></h4>
                                       </Col>
                                   </Row>
                               </Card.Header>
                               <Card.Body>
                                   <Form app={newApp} creating="true" setEditMode={setEditMode} />
                               </Card.Body>
                           </Card>
                       </Col>
                   </Row>
               </Fragment>
           )}
           {contextApplication.applications.map((app, index) => {
               return <Row key={app.name}>
                   <Col md={12} className="mx-auto mb-3">
                        <Item app={app} index={index}  />
                   </Col>
               </Row>
           })}

       </Container>
   )
}

export default withRouter(Index);