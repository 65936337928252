import axios from 'axios'
//import env from "react-dotenv";

axios.defaults.baseURL = process.env.REACT_APP_API_URL

axios.interceptors.request.use(
    request => {
        request.headers['Authorization'] = `Bearer ${localStorage.getItem('api_token')}`
        return request;
    }
);

axios.interceptors.response.use((res) => {
    return res;
}, (err) => {
    //token expired
    if(err.response.status === 401){
        window.location.href = '/';
        localStorage.clear();
    }
    else{
       return Promise.reject(err)
    }
})

export function get(url, params=null) {
    return axios({
        method: 'GET',
        url: url,
        params: params

    })
}

export function post(url, payload) {
    return axios({
        method: 'POST',
        url: url,
        data: payload,
    })
}

export function put(url, payload) {
    return axios({
        method: 'PUT',
        url: url,
        data: payload,
    })
}


// delete is reserved keyword
export function del(url) {
    return axios({
        method: 'DELETE',
        url: url,
    })
}
/**/


